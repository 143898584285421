import hisWork from './hisWork'
import pun from './pun'
import rew from './rew'
import train from './train'
import worker from './worker'
import workerEdit from './workerEdit'
import workerEdu from './workerEdu'
import workerAuth from '../susong/worker_auth_fun'
import workerIn from './workerIn'
import workerOut from './workerOut'
import workerCertificate from './workerCertificate'
import workerCertificateDoctorPraCount from './workerCertificateDoctorPraCount'
import workerIdCard from './workerIdCard'
import workerJobTitle from './workerJobTitle'
import workerJobTitleCurrent from './workerJobTitleCurrent'
import jobTitleCurrent from './jobTitleCurrent'
import workerJobTitlePromotion from './workerJobTitlePromotion'
import workerJobTitleQueue from './workerJobTitleQueue'
import workerJobTitleQueueWarning from './workerJobTitleQueueWarning'
import workerConfirmStatus from './worker/confirmStatus'
import count from './count'
import person from './person'
import agreement from './agreement'
import appraisal from './appraisal'
import wage from './wage'
import agreementWarning from './agreementWarning'
import agreementTurnWarning from './agreementTurnWarning'
import askLeave from './askLeave'
import askLeaveMy from './askLeaveMy'
import mySalary from './mySalary'
import certificate from './certificate'
import jobTitle from './jobTitle'
import appraisalManage from './appraisalManage'
import infoRate from './infoRate'
import wageTongcheng from './wageTongcheng'
import operationLog from './operationLog'
import activityType from './activityType'
import activity from './activity'
import activityMy from './activityMy'
import articleType from './articleType'
import article from './article'
import articleMy from './articleMy'

import docManage from './docManage'
import docHandle from './docHandle'
import docHome from './docHome'
import docSend from './docSend'
import docSendManage from './docSendManage'
import docSendSignin from './docSendSignin'

import issuesManage from './issuesManage'
import issuesHandle from './issuesHandle'

import countBase from './countBase'
import countHome from './countHome'
import countCount from './countCount'

import wageJobTitle from './wageJobTitle'
import wageLevel from './wageLevel'
import performanceWorker from './performanceWorker'
import workerMonth from './workerMonth'
import workerSubsidy from './workerSubsidy'
import workerWage from './workerWage'
import workerWageJobTitle from './workerWageJobTitle'
import workerWageLevel from './workerWageLevel'
import reportNucleic from './report/nucleic'
import workerBatchEdit from './workerBatchEdit'
import analysisAge from './analysis/age'
import analysisMedical from './analysis/medical'
import workerPool from './workerPool'
import askLeaveApply from './askLeaveApply'
import askLeaveApplyRecrod from './askLeaveApplyRecrod'
import askLeaveHome from './askLeaveHome'
import evaluation from './evaluation/index'
import appDoc from './app/doc/index'
import appVacate from './app/vacate/index'
import askLeaveApplyType from './askLeaveApplyType'

import copSalary from './worker/salary/cop/index'

export default [
  {
    routerNames: ['count.base'],
    funs: countBase
  },
  {
    routerNames: ['count.home'],
    funs: countHome
  },
  {
    routerNames: ['count.count'],
    funs: countCount
  },
  {
    routerNames: ['count.config'],
    funs: {}
  },
  {
    routerNames: ['doc.manage', 'doc.manage_hywj', 'doc.manage_qtwj'],
    funs: docManage
  },
  {
    routerNames: ['issues.manage'],
    funs: issuesManage
  },
  {
    routerNames: ['doc.handle', 'doc.handle_hywj', 'wap.doc.handle', 'wap.doc.step'],
    funs: docHandle
  },
  {
    routerNames: ['doc.home'],
    funs: docHome
  },
  {
    routerNames: ['doc.send_manage', '/pages/doc/issued/index?type=manage'],
    funs: docSendManage
  },
  {
    routerNames: ['doc.send_signin', '/pages/doc/issued/index?type=sign'],
    funs: docSendSignin
  },
  {
    routerNames: ['doc.send', '/pages/doc/issued/index?type=approve'],
    funs: docSend
  },
  {
    routerNames: ['issues.handle'],
    funs: issuesHandle
  },
  {
    routerNames: ['performance.worker_month'],
    funs: workerMonth
  },
  {
    routerNames: ['performance.worker_subsidy'],
    funs: workerSubsidy
  },
  {
    routerNames: ['performance.worker_wage'],
    funs: workerWage
  },
  {
    routerNames: ['performance.worker_wage_job_title'],
    funs: workerWageJobTitle
  },
  {
    routerNames: ['performance.worker_wage_level'],
    funs: workerWageLevel
  },
  {
    routerNames: ['performance.wage_job_title'],
    funs: wageJobTitle
  },
  {
    routerNames: ['performance.wage_level'],
    funs: wageLevel
  },
  {
    routerNames: ['performance.worker'],
    funs: performanceWorker
  },
  {
    routerNames: ['activity.type'],
    funs: activityType
  },
  {
    routerNames: ['activity.manage', 'activity.show'],
    funs: activity
  },
  {
    routerNames: ['activity.my'],
    funs: activityMy
  },
  {
    routerNames: ['article.type'],
    funs: articleType
  },
  {
    routerNames: ['article.manage'],
    funs: article
  },
  {
    routerNames: ['article.my'],
    funs: articleMy
  },
  {
    routerNames: ['worker.inforate'],
    funs: infoRate
  },
  {
    routerNames: ['worker.worker_idcard'],
    funs: workerIdCard
  },
  {
    routerNames: ['worker.agreement_turn_warning'],
    funs: agreementTurnWarning
  },
  {
    routerNames: ['worker.confirm_status'],
    funs: workerConfirmStatus
  },
  {
    routerNames: ['authority.operation_log'],
    funs: operationLog
  },
  {
    routerNames: ['base.job_title'],
    funs: jobTitle
  },
  {
    routerNames: ['base.certificate'],
    funs: certificate
  },
  {
    routerNames: ['ask_leave.home'],
    funs: askLeaveHome
  },
  {
    routerNames: ['ask_leave.apply'],
    funs: askLeaveApply
  },
  {
    routerNames: ['ask_leave.apply_assign'],
    funs: askLeaveApply
  },
  {
    routerNames: ['ask_leave.apply_record'],
    funs: askLeaveApplyRecrod
  },
  {
    routerNames: ['ask_leave.my'],
    funs: askLeaveMy
  },
  {
    routerNames: ['ask_leave.record'],
    funs: askLeave
  },
  {
    routerNames: ['ask_leave.switch_config'],
    funs: askLeaveApplyType
  },
  {
    routerNames: ['worker.worker_certificate_doctor_pra_count'],
    funs: workerCertificateDoctorPraCount
  },
  {
    routerNames: ['worker.worker_certificate', 'worker.worker_certificate_doctor_qua'],
    funs: workerCertificate
  },
  {
    routerNames: ['worker.worker_certificate_doctor_pra'],
    funs: Object.assign({
      changelog: {
        funKey: 'changelog',
        name: '变更记录'
      }
    }, workerCertificate)
  },
  {
    routerNames: ['worker.worker_certificate_pro_hire'],
    funs: Object.assign({
      xp: {
        funKey: 'xp',
        name: '续聘',
        apis: [{
          path: '/basis/worker_certificate/{id}',
          method: 'PUT'
        }]
      }
    }, workerCertificate)
  },
  {
    routerNames: ['worker.worker_certificate_other'],
    funs: Object.assign({}, workerCertificate)
  },
  {
    routerNames: ['worker.agreement_warning'],
    funs: agreementWarning
  },
  {
    routerNames: ['worker.wage'],
    funs: wage
  },
  {
    routerNames: ['worker.wage_tongcheng'],
    funs: wageTongcheng
  },
  {
    routerNames: ['worker.worker_job_title'],
    funs: workerJobTitle
  },
  {
    routerNames: ['worker.worker_job_current'],
    funs: workerJobTitleCurrent
  },
  {
    routerNames: ['worker.job_title_current'],
    funs: jobTitleCurrent
  },
  {
    routerNames: ['worker.job_title_promotion'],
    funs: workerJobTitlePromotion
  },
  {
    routerNames: ['worker.job_title_queue'],
    funs: workerJobTitleQueue
  },
  {
    routerNames: ['worker.job_title_queue_warning'],
    funs: workerJobTitleQueueWarning
  },
  {
    routerNames: ['appraisal.ready', 'appraisal.doing', 'appraisal.checking', 'appraisal.vote', 'appraisal.question', 'appraisal.my', 'wap.my', 'wap.question', 'wap.vote', 'wap.checking', 'screen', '/pages/appraisal/my/index'],
    funs: appraisal
  },
  {
    routerNames: ['appraisal.manage', '/pages/appraisal/manage'],
    funs: appraisalManage
  },
  {
    routerNames: ['wap.salary', 'wap.salary_tongcheng'],
    funs: mySalary
  },
  {
    routerNames: ['worker.agreement'],
    funs: agreement
  },
  {
    routerNames: ['person.index'],
    funs: person
  },
  {
    routerNames: ['worker.count'],
    funs: count
  },
  {
    routerNames: ['worker.worker_out'],
    funs: workerOut
  },
  {
    routerNames: ['worker.worker_in'],
    funs: workerIn
  },
  {
    routerNames: ['worker.train'],
    funs: train
  },
  {
    routerNames: ['worker.rew'],
    funs: rew
  },
  {
    routerNames: ['worker.pun'],
    funs: pun
  },
  {
    routerNames: ['worker.his_work'],
    funs: hisWork
  },
  {
    routerNames: ['worker.worker'],
    funs: worker
  },
  {
    routerNames: ['/pages/worker/my'],
    funs: {
      my: {
        funKey: 'my',
        name: '查看我的',
        apis: [
          { path: '/basis/worker/detail/my', method: 'GET' }
        ]
      }
    }
  },
  {
    routerNames: ['worker.edit'],
    funs: workerBatchEdit
  },
  {
    routerNames: ['worker.worker_edit'],
    funs: workerEdit
  },
  {
    routerNames: ['worker.worker_edu'],
    funs: workerEdu
  },
  {
    routerNames: ['worker.worker_auth'],
    funs: workerAuth
  },
  {
    routerNames: ['wap.menu', 'wap.salary', 'wap.salary_tongcheng'],
    funs: {
      getSalary: {
        funKey: 'getSalary',
        name: '获取上月工资明细',
        apis: [{
          path: '/basis/wage',
          method: 'GET'
        }]
      }
    }
  },
  {
    routerNames: [
      'report.nucleic.data',
      'report.nucleic.result',
      'report.nucleic.print',
      'report.nucleic.counts'
    ],
    funs: reportNucleic
  },
  {
    routerNames: ['analysis.age'],
    funs: analysisAge
  },
  {
    routerNames: ['analysis.medical'],
    funs: analysisMedical
  },
  {
    routerNames: ['analysis.type', 'analysis.position', 'analysis.political_status', 'analysis.job_type', 'analysis.edu', 'analysis.job_level', 'analysis.job_title'],
    funs: workerPool
  },
  {
    routerNames: ['evaluation.index', 'evaluation.details', 'evaluation.details.draw', 'evaluation.details.opinion', 'evaluation.details.approve'],
    funs: evaluation
  },
  {
    routerNames: ['/pages/doc/index', '/pages/doc/manage', '/pages/meeting/file/index', '/pages/meeting/file/manage'],
    funs: appDoc
  },
  {
    routerNames: ['/pages/vacate/index?type=my', '/pages/vacate/index?type=distribution', '/pages/vacate/index?type=approval', '/pages/vacate/record?type=terminate'],
    funs: appVacate
  },
  ...copSalary
]