import * as url from './url'

import {
  request,
  httpRequestMethods
} from '@/api'

export const generateBasisRequest = function (urlParm) {
  return {
    get: function (parm) {
      return request(urlParm.basUrl, httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(urlParm.basUrl, httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(urlParm.parmUrl(id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(urlParm.parmUrl(id), httpRequestMethods.DELETE)
    }
  }
}

export const generateBasisDataRequest = function (name) {
  return {
    get: function (parm) {
      return request(url.data.get(name), httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(url.data.add(name), httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(url.data.update(name, id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(url.data.del(name, id), httpRequestMethods.DELETE)
    }
  }
}

export const generateCommonRequest = function (name) {
  return {
    get: function (parm) {
      return request(url.common.get(name), httpRequestMethods.GET, parm)
    },
    add: function (parm) {
      return request(url.common.add(name), httpRequestMethods.POST, parm)
    },
    update: function (id, parm) {
      return request(url.common.update(name, id), httpRequestMethods.PUT, parm)
    },
    del: function (id) {
      return request(url.common.del(name, id), httpRequestMethods.DELETE)
    }
  }
}

export const refQueryRequestHander = async function (key, parm) {
  return await request(url.refQuery.get(key), httpRequestMethods.GET, parm)
}


export const dataAuditLogReuqest = {
  get: function (parm) {
    return request(url.dataAuditLog.base, httpRequestMethods.GET, parm)
  }
}

export const workOperationReuqest = {
  getLog: function (parm) {
    return request(url.workOperation.log, httpRequestMethods.GET, parm)
  }
}

// confQuery
export const confQueryRequest = generateCommonRequest('conf_query')

export const workerRequest = Object.assign(generateCommonRequest('worker'), {
  getAll (parm) {
    return request('/basis/worker/all', httpRequestMethods.GET, parm)
  },
  getMy (parm) {
    return request('/basis/worker/my', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/basis/worker/my_org', httpRequestMethods.GET, parm)
  },
  getMyOrgContainChild (parm) {
    return request('/basis/worker/my_org_contain_child', httpRequestMethods.GET, parm)
  },
  getDetail (parm) {
    return request(url.worker.getDetail, httpRequestMethods.GET, parm)
  },
  getDetailMy (parm) {
    return request('/basis/worker/detail/my', httpRequestMethods.GET, parm)
  },
  getDetailMyOrg (parm) {
    return request('/basis/worker/detail/my_org', httpRequestMethods.GET, parm)
  },
  getDetailMyOrgContainChild (parm) {
    return request('/basis/worker/detail/my_org_contain_child', httpRequestMethods.GET, parm)
  },
  getEdit (parm) {
    return request('/basis/worker/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/worker/edit', httpRequestMethods.PUT, parm)
  },
  batchSave (parm) {
    return request('/basis/worker/batch', httpRequestMethods.POST, parm)
  }
})

export const workerTypeRequest = generateCommonRequest('worker_type')

export const salaryRequest = {
  get_worker_salary (parm) {
    return request(url.salary.get_worker_salary, httpRequestMethods.GET, parm)
  },
  volume (parm) {
    return request(url.salary.volume, httpRequestMethods.GET, parm)
  },
  init (parm) {
    return request(url.salary.init, httpRequestMethods.POST, parm)
  },
  initWorker (parm) {
    return request(url.salary.initWorker, httpRequestMethods.POST, parm)
  },
  addOrg (parm) {
    return request(url.salary.addOrg, httpRequestMethods.POST, parm)
  },
  addWorker (parm) {
    return request(url.salary.addWorker, httpRequestMethods.POST, parm)
  },
  count (parm) {
    return request(url.salary.count, httpRequestMethods.GET, parm)
  }
}

export const agreementRequest = Object.assign(generateCommonRequest('agreement'), {
  getWarning (parm) {
    return request(url.agreement.warning, httpRequestMethods.GET, parm)
  },
  sendWarning (parm) {
    return request('/basis/agreement/send_warning', httpRequestMethods.POST, parm)
  },
  getTurn (parm) {
    parm.jobTypeC = '试用期'
    parm.needTry = '是'
    return generateCommonRequest('agreement').get(parm)
  },
  getMy (parm) {
    return request('/basis/agreement/my', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/basis/agreement/my_org', httpRequestMethods.GET, parm)
  },
  getMyOrgContainChild (parm) {
    return request('/basis/agreement/my_org_contain_child', httpRequestMethods.GET, parm)
  }
})

// appraisal
export const appraisalRequest = Object.assign(generateCommonRequest('appraisal'), {
  switchStatus (id, parm) {
    return request('/basis/appraisal/status/' + id, httpRequestMethods.PUT, parm)
  },
  reStart (id) {
    return request('/basis/appraisal/re_start/' + id, httpRequestMethods.PUT)
  },
  updateFile (id, parm) {
    return request('/basis/appraisal/file/' + id, httpRequestMethods.PUT, parm)
  }
})

// appraisalItem
export const appraisalItemRequest = generateCommonRequest('appraisal_item')

// appraisal_group
export const appraisalGroupRequest = generateCommonRequest('appraisal_group')

// appraisal_question
export const appraisalQuestionRequest = generateBasisDataRequest('AppraisalQuestion')

// appraisal_group_detail
export const appraisalGroupDetailRequest = Object.assign(generateCommonRequest('appraisal_group_detail'), {
  getMy (parm) {
    return request('/basis/appraisal_group_detail/my', httpRequestMethods.GET, parm)
  },
  switchStatus (id, parm) {
    return request('/basis/appraisal_group_detail/status/' + id, httpRequestMethods.PUT, parm)
  }
})

// appraisal_result
export const appraisalResultRequest = Object.assign(generateCommonRequest('appraisal_result'), {
  getDetail (parm) {
    return request('/basis/appraisal_result/detail', httpRequestMethods.GET, parm)
  },
  calculate (id) {
    return request('/basis/appraisal_result/calculate/' + id, httpRequestMethods.POST)
  },
  vote (parm) {
    return request('/basis/appraisal_result/vote', httpRequestMethods.POST, parm)
  }
})

// jobTitle
export const jobTitleRequest = Object.assign(generateCommonRequest('job_title'), {
  getQueue (parm) {
    return request(url.jobTitle.getQueue, httpRequestMethods.GET, parm)
  },
  getMatch (parm) {
    return request('/basis/job_title/promotion', httpRequestMethods.GET, parm)
  },
  getMatchMy (parm) {
    return request('/basis/job_title/promotion/my', httpRequestMethods.GET, parm)
  },
  getMatchMyOrg (parm) {
    return request('/basis/job_title/promotion/my_org', httpRequestMethods.GET, parm)
  },
  getMatchMyOrgContainChild (parm) {
    return request('/basis/job_title/promotion/my_org_contain_child', httpRequestMethods.GET, parm)
  },
  getQueueWarning (parm) {
    return request(url.jobTitle.getQueueWarning, httpRequestMethods.GET, parm)
  },
  getCurrent (parm) {
    return request('/basis/worker_job_title/current', httpRequestMethods.GET, parm)
  },
  getCurrentMy (parm) {
    return request('/basis/worker_job_title/current/my', httpRequestMethods.GET, parm)
  },
  getCurrentMyOrg (parm) {
    return request('/basis/worker_job_title/current/my_org', httpRequestMethods.GET, parm)
  },
  getCurrentMyOrgContainChild (parm) {
    return request('/basis/worker_job_title/current/my_org_contain_child', httpRequestMethods.GET, parm)
  }
})

// certificate
export const certificateRequest = generateCommonRequest('certificate')

// workerJobTitle
export const workerJobTitleRequest = Object.assign(generateCommonRequest('worker_job_title'), {
  getEdit (parm) {
    return request('/basis/worker_job_title/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/worker_job_title/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/worker_job_title/edit', httpRequestMethods.POST, parm)
  },
  getMy (parm) {
    return request('/basis/worker_job_title/my', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/basis/worker_job_title/my_org', httpRequestMethods.GET, parm)
  },
  getMyOrgContainChild (parm) {
    return request('/basis/worker_job_title/my_org_contain_child', httpRequestMethods.GET, parm)
  }
})

// WorkerCertificate
export const workerCertificateRequest = Object.assign(generateCommonRequest('worker_certificate'), {
  getEdit (parm) {
    return request('/basis/worker_certificate/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/worker_certificate/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/worker_certificate/edit', httpRequestMethods.POST, parm)
  },
  getMy (parm) {
    return request('/basis/worker_certificate/my', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/basis/worker_certificate/my_org', httpRequestMethods.GET, parm)
  },
  getMyOrgContainChild (parm) {
    return request('/basis/worker_certificate/my_org_contain_child', httpRequestMethods.GET, parm)
  }
})

// wage
export const wageRequest = Object.assign(generateCommonRequest('wage'), {
  clear (parm) {
    return request(url.wage.clear, httpRequestMethods.POST, parm)
  },
  getMy (parm) {
    return request('/basis/wage/my', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/basis/wage/my_org', httpRequestMethods.GET, parm)
  },
  getMyOrgContainChild (parm) {
    return request('/basis/wage/my_org_contain_child', httpRequestMethods.GET, parm)
  }
})

export function dataerImportRequest (file, sparm) {
  let parm = new FormData()
  parm.append('file', file)
  Object.keys(sparm).forEach(v => {
    parm.append(v, sparm[v])
  })
  return request(url.dataerImport.url, httpRequestMethods.POST, parm, {
    contentType: 'multipart/form-data'
  })
}

// train
export const trainRequest = Object.assign(generateCommonRequest('train'), {
  getEdit (parm) {
    return request('/basis/train/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/train/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/train/edit', httpRequestMethods.POST, parm)
  },
  getMy (parm) {
    return request('/basis/train/my', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/basis/train/my_org', httpRequestMethods.GET, parm)
  },
  getMyOrgContainChild (parm) {
    return request('/basis/train/my_org_contain_child', httpRequestMethods.GET, parm)
  }
})

// rew_pun
export const rewPunRequest = Object.assign(generateCommonRequest('rew_pun'), {
  getEdit (parm) {
    return request('/basis/rew_pun/edit', httpRequestMethods.GET, parm)
  },
  getMy (parm) {
    return request('/basis/rew_pun/my', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/basis/rew_pun/my_org', httpRequestMethods.GET, parm)
  },
  getMyOrgContainChild (parm) {
    return request('/basis/rew_pun/my_org_contain_child', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/rew_pun/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/rew_pun/edit', httpRequestMethods.POST, parm)
  }
})

// rew_pun
export const operationLogRequest = {
  get (parm) {
    return request(url.operationLog.get, httpRequestMethods.GET, parm)
  }
}

// ask_leave
export const askLeaveRequest = Object.assign(generateCommonRequest('ask_leave'), {
  getMy (parm) {
    return request('/basis/ask_leave/my', httpRequestMethods.GET, parm)
  },
  off (id, parm) {
    return request('/basis/ask_leave/off/' + id, httpRequestMethods.POST, parm)
  },
  backOff (id) {
    return request('/basis/ask_leave/back_off/' + id, httpRequestMethods.POST)
  }
})

// askLeaveApply
export const askLeaveApplyRequest = Object.assign(generateCommonRequest('ask_leave_apply'), {
  switchStatus (id, parm) {
    return request('/basis/ask_leave_apply/switch/' + id, httpRequestMethods.POST, parm)
  },
  getByAuth (parm) {
    return request('/basis/ask_leave_apply/by_auth', httpRequestMethods.GET, parm)
  },
  assign (id, parm) {
    return request('/basis/ask_leave_apply/assign/' + id, httpRequestMethods.PUT, parm)
  },
  files (id, parm) {
    return request('/basis/ask_leave_apply/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  getType (parm) {
    return request('/basis/ask_leave_apply/type', httpRequestMethods.GET, parm)
  },
  addType (parm) {
    return request('/basis/ask_leave_apply/type', httpRequestMethods.POST, parm)
  },
  updateType (id, parm) {
    return request('/basis/ask_leave_apply/type/' + id, httpRequestMethods.PUT, parm)
  },
  updateTypeFlow (id, parm) {
    return request('/basis/ask_leave_apply/type/flow/' + id, httpRequestMethods.PUT, parm)
  },
  delType (id) {
    return request('/basis/ask_leave_apply/type/' + id, httpRequestMethods.DELETE)
  }
})

// his_work
export const hisWorkRequest = Object.assign(generateCommonRequest('his_work'), {
  getEdit (parm) {
    return request('/basis/his_work/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/his_work/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/his_work/edit', httpRequestMethods.POST, parm)
  },
  getMy (parm) {
    return request('/basis/his_work/my', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/basis/his_work/my_org', httpRequestMethods.GET, parm)
  },
  getMyOrgContainChild (parm) {
    return request('/basis/his_work/my_org_contain_child', httpRequestMethods.GET, parm)
  }
})

// worker_edu
export const workerEduRequest = Object.assign(generateCommonRequest('worker_edu'), {
  getEdit (parm) {
    return request('/basis/worker_edu/edit', httpRequestMethods.GET, parm)
  },
  saveEdit (parm) {
    return request('/basis/worker_edu/edit', httpRequestMethods.PUT, parm)
  },
  addEdit (parm) {
    return request('/basis/worker_edu/edit', httpRequestMethods.POST, parm)
  },
  getMy (parm) {
    return request('/basis/worker_edu/my', httpRequestMethods.GET, parm)
  },
  getMyOrg (parm) {
    return request('/basis/worker_edu/my_org', httpRequestMethods.GET, parm)
  },
  getMyOrgContainChild (parm) {
    return request('/basis/worker_edu/my_org_contain_child', httpRequestMethods.GET, parm)
  }
})

// workerAuth
export const workerAuthRequest = generateCommonRequest('worker_auth')

export const dataAppleRequest = {
  getDraft (parm) {
    return request('/basis/modify_apply/draft', httpRequestMethods.GET, parm)
  },
  getWait (parm) {
    return request('/basis/modify_apply/wait', httpRequestMethods.GET, parm)
  },
  getWaitDetail (parm) {
    return request('/basis/modify_apply/wait/detail', httpRequestMethods.GET, parm)
  },
  saveDraft (parm) {
    return request('/basis/modify_apply/draft', httpRequestMethods.POST, parm)
  },
  multipleSubmit (workerId, parm) {
    return request('/basis/modify_apply/multiple/submit/' + workerId, httpRequestMethods.POST, parm)
  },
  check (id, parm) {
    return request('/basis/modify_apply/check/' + id, httpRequestMethods.POST, parm)
  },
  submitByWorkerId (id, parm) {
    return request('/basis/modify_apply/submit/worker/' + id, httpRequestMethods.POST, parm)
  }
}

// count_group
export const countGroupRequest = Object.assign(generateCommonRequest('count_group'), {
  delDetail (id) {
    return request('/basis/count_group/detail/' + id, httpRequestMethods.DELETE)
  },
  generateDetail (id) {
    return request('/basis/count_group/detail/' + id, httpRequestMethods.POST)
  }
})

// count_worker
export const countWorkerRequest = generateCommonRequest('count_worker')

// official_document
export const officialDocumentRequest = Object.assign(generateCommonRequest('official_document'), {
  back (id) {
    return request('/basis/official_document/back/' + id, httpRequestMethods.PUT)
  },
  over (id) {
    return request('/basis/official_document/over/' + id, httpRequestMethods.PUT)
  },
  getDetail (parm) {
    return request('/basis/official_document/detail', httpRequestMethods.GET, parm)
  },
  getLog (parm) {
    return request('/basis/official_document/log', httpRequestMethods.GET, parm)
  }
})

// official_document_handle_step
export const officialDocumentHandleStepRequest = generateCommonRequest('official_document_handle_step')

// official_document_handle
export const officialDocumentHandleRequest = Object.assign(generateCommonRequest('official_document_handle'), {
  getMy (parm) {
    return request('/basis/official_document_handle/my', httpRequestMethods.GET, parm)
  }
})

// officialDocumentSend
export const officialDocumentSendRequest = Object.assign(generateCommonRequest('official_document_send'), {
  updateSendCode (id, parm) {
    return request('/basis/official_document_send/send_code/' + id, httpRequestMethods.PUT, parm)
  },
  switchStatus (id, parm) {
    return request('/basis/official_document_send/switch/' + id, httpRequestMethods.POST, parm)
  },
  getByAuth (parm) {
    return request('/basis/official_document_send/by_auth', httpRequestMethods.GET, parm)
  },
  assign (id, parm) {
    return request('/basis/official_document_send/assign/' + id, httpRequestMethods.PUT, parm)
  },
  orgAssign (id, parm) {
    return request('/basis/official_document_send/assign/org/' + id, httpRequestMethods.PUT, parm)
  },
  files (id, parm) {
    return request('/basis/official_document_send/file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  overFiles (id, parm) {
    return request('/basis/official_document_send/over_file_ids/' + id, httpRequestMethods.PUT, parm)
  },
  getMyHandle (parm) {
    return request('/basis/official_document_send/handle/my_org', httpRequestMethods.GET, parm)
  },
  getHandle (parm) {
    return request('/basis/official_document_send/handle', httpRequestMethods.GET, parm)
  },
  handle (id) {
    return request('/basis/official_document_send/handle/' + id, httpRequestMethods.POST)
  },
  signin (id) {
    return request('/basis/official_document_send/signin/' + id, httpRequestMethods.POST)
  },
  signinBack (id) {
    return request('/basis/official_document_send/signin/back/' + id, httpRequestMethods.POST)
  },
  getUpcoming () {
    return request('/basis/official_document_send/upcoming', httpRequestMethods.GET)
  }
})

// activity
export const activityRequest = Object.assign(generateCommonRequest('activity'), {
  getByAuth (parm) {
    return request('/basis/activity/by_auth', httpRequestMethods.GET, parm)
  },
  edit (id, parm) {
    return request('/basis/activity/edit/' + id, httpRequestMethods.PUT, parm)
  }
})

// activityType
export const activityTypeRequest = generateCommonRequest('activity_type')

// activity
export const articleRequest = Object.assign(generateCommonRequest('article'), {
  getByAuth (parm) {
    return request('/basis/article/by_auth', httpRequestMethods.GET, parm)
  },
  edit (id, parm) {
    return request('/basis/article/edit/' + id, httpRequestMethods.PUT, parm)
  }
})

// articleType
export const articleTypeRequest = generateCommonRequest('article_type')


// performance

export const workerWageRequest = {
  get: function (parm) {
    return request('/performance/worker_wage', httpRequestMethods.GET, parm)
  },
  add: function (parm) {
    return request('/performance/worker_wage', httpRequestMethods.POST, parm)
  },
  update: function (id, parm) {
    return request('/performance/worker_wage', httpRequestMethods.POST, parm)
  },
  updateValue: function (id, parm) {
    return request('/performance/worker_wage/' + id, httpRequestMethods.PUT, parm)
  },
  batch: function (parm) {
    return request('/performance/worker_wage/batch', httpRequestMethods.POST, parm)
  },
  batchSave: function (parm) {
    return request('/performance/worker_wage/batch', httpRequestMethods.PUT, parm)
  },
  del: function (id) {
    return request('/performance/worker_wage/' + id, httpRequestMethods.DELETE)
  }
}

export const wageJobTitleRequest = {
  get: function (parm) {
    return request('/performance/wage_job_title', httpRequestMethods.GET, parm)
  },
  add: function (parm) {
    return request('/performance/wage_job_title', httpRequestMethods.POST, parm)
  },
  update: function (id, parm) {
    return request('/performance/wage_job_title/' + id, httpRequestMethods.PUT, parm)
  },
  del: function (id) {
    return request('/performance/wage_job_title/' + id, httpRequestMethods.DELETE)
  }
}

export const wageLevelRequest = {
  get: function (parm) {
    return request('/performance/wage_level', httpRequestMethods.GET, parm)
  },
  add: function (parm) {
    return request('/performance/wage_level', httpRequestMethods.POST, parm)
  },
  update: function (id, parm) {
    return request('/performance/wage_level/' + id, httpRequestMethods.PUT, parm)
  },
  del: function (id) {
    return request('/performance/wage_level/' + id, httpRequestMethods.DELETE)
  }
}

export const performanceWorkerRequest = {
  get: function (parm) {
    return request('/performance/worker', httpRequestMethods.GET, parm)
  },
  add: function (parm) {
    return request('/performance/worker', httpRequestMethods.POST, parm)
  },
  update: function (id, parm) {
    return request('/performance/worker/' + id, httpRequestMethods.PUT, parm)
  },
  del: function (id) {
    return request('/performance/worker/' + id, httpRequestMethods.DELETE)
  }
}

export const nucleicReportRequest = {
  get: function (parm) {
    return request('/basis/nucleic_report', httpRequestMethods.GET, parm)
  },
  getMyOrg: function (parm) {
    return request('/basis/nucleic_report/my_org', httpRequestMethods.GET, parm)
  },
  update: function (parm, type = '') {
    return request('/basis/nucleic_report' + (type ? ('/' + type) : ''), httpRequestMethods.POST, parm)
  },
  save: function (id, parm, type = '') {
    return request('/basis/nucleic_report' + (type ? ('/' + type) : '') + '/' + id, httpRequestMethods.PUT, parm)
  },
  del: function (parm) {
    return request('/basis/nucleic_report', httpRequestMethods.DELETE, parm)
  },
  cydd: function () {
    return request('/basis/nucleic_report/cydd', httpRequestMethods.GET)
  },
  counts: function (parm) {
    return request('/basis/nucleic_report/count', httpRequestMethods.GET, parm)
  },
  countsMyOrg: function (parm) {
    return request('/basis/nucleic_report/count/my_org', httpRequestMethods.GET, parm)
  },
  jsrq: function (parm) {
    return request('/basis/nucleic_report/jsrq', httpRequestMethods.POST, parm)
  }
}

export const analysisRequest = {
  certificateLevel (parm) {
    return request('/basis/worker/certificate_level', httpRequestMethods.GET, parm)
  },
  certificateLevelMyOrg (parm) {
    return request('/basis/worker/certificate_level/my_org', httpRequestMethods.GET, parm)
  },
  certificateLevelMyOrgContainChild (parm) {
    return request('/basis/worker/certificate_level/my_org_contain_child', httpRequestMethods.GET, parm)
  }
}

export const statusManageRequest = {
  switchConfig (parm) {
    return request('/basis/status_manage/switch_config', httpRequestMethods.GET, parm)
  },
  config (parm) {
    return request('/basis/status_manage/config', httpRequestMethods.GET, parm)
  },
  reloadGroup () {
    return request('/basis/status_manage/reload', httpRequestMethods.GET)
  },
  getGroup () {
    return request('/basis/status_manage', httpRequestMethods.GET)
  },
  updateGroup (parm) {
    return request('/basis/status_manage', httpRequestMethods.POST, parm)
  },
  delGroup (id) {
    return request('/basis/status_manage/' + id, httpRequestMethods.DELETE)
  },
  updateStatus (parm) {
    return request('/basis/status_manage/status', httpRequestMethods.POST, parm)
  },
  delStatus (id) {
    return request('/basis/status_manage/status/' + id, httpRequestMethods.DELETE)
  },
  addSwitch (parm) {
    return request('/basis/status_manage/switch', httpRequestMethods.POST, parm)
  },
  updateSwitch (id, parm) {
    return request('/basis/status_manage/switch/' + id, httpRequestMethods.PUT, parm)
  },
  delSwitch (id) {
    return request('/basis/status_manage/switch/' + id, httpRequestMethods.DELETE)
  },
  addAction (parm) {
    return request('/basis/status_manage/action', httpRequestMethods.POST, parm)
  },
  updateAction (id, parm) {
    return request('/basis/status_manage/action/' + id, httpRequestMethods.PUT, parm)
  },
  delAction (id) {
    return request('/basis/status_manage/action/' + id, httpRequestMethods.DELETE)
  },
  addCondition (parm) {
    return request('/basis/status_manage/condition', httpRequestMethods.POST, parm)
  },
  updateCondition (id, parm) {
    return request('/basis/status_manage/condition/' + id, httpRequestMethods.PUT, parm)
  },
  delCondition (id) {
    return request('/basis/status_manage/condition/' + id, httpRequestMethods.DELETE)
  }
}

export const appraisalYearRequest = {
  get (parm) {
    return request('/basis/appraisal_year', httpRequestMethods.GET, parm)
  },
  add (parm) {
    return request('/basis/appraisal_year', httpRequestMethods.POST, parm)
  },
  update (id, parm) {
    return request('/basis/appraisal_year/' + id, httpRequestMethods.PUT, parm)
  },
  del (id) {
    return request('/basis/appraisal_year/' + id, httpRequestMethods.DELETE)
  },
  status (id, parm) {
    return request('/basis/appraisal_year/status/' + id, httpRequestMethods.PUT, parm)
  }
}

export const appraisalYearDetailsRequest = {
  get (parm) {
    return request('/basis/appraisal_year/detail', httpRequestMethods.GET, parm)
  },
  getMy (parm) {
    return request('/basis/appraisal_year/detail/my', httpRequestMethods.GET, parm)
  },
  update (id, parm) {
    return request('/basis/appraisal_year/detail/' + id, httpRequestMethods.PUT, parm)
  },
  status (id, parm) {
    return request('/basis/appraisal_year/detail/status/' + id, httpRequestMethods.PUT, parm)
  },
  check (parm) {
    return request('/basis/appraisal_year/check', httpRequestMethods.POST, parm)
  }
}